import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import classNames from "classnames"

import Input from "components/Input"
import Button from "components/Button"
import FormLabel from "components/FormGroup/FormLabel"
import Separator from "components/Separator"
import ButtonGoogleLogin from "components/ButtonGoogleLogin"
import BirthdayForm from "components/BirthdayForm"
import GenderForm from "components/GenderForm"
import ButtonAppleLogin from "components/ButtonAppleLogin"

// Actions
import { createAccount } from "actions/account"
import { set as setSignup } from "actions/signup"
import { facebookLogin, googleLogin, clearLoginError, appleLogin } from "actions/session"

import selectAuthentication from "selectors/authentication"
import Formatter from "helpers/formatter"
import TrackingSegment from "managers/segmentManager"
import routeHelpers from "helpers/route"
import { getAssetURL } from "helpers/static"
import { updateMeta, restoreDefaultMeta } from "helpers/meta"

import consts from "consts"
import i18n from "consts/i18n"
import routes from "consts/routes"

import styles from "./index.css"

@connect(state => {
  return {
    account: state.account,
    authentication: selectAuthentication(state),
    router: state.router.location,
  }
})
export default class PageSignup extends Component {
  static propTypes = {
    account: PropTypes.object.isRequired,
    deviceTargetAgent: PropTypes.object,
    displayEmailSignup: PropTypes.bool,
    onClickConnect: PropTypes.func.isRequired,
    authentication: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    errorMessages: PropTypes.object,
    showGoogleConnect: PropTypes.bool,
    signUpOrigin: PropTypes.string,
    title: PropTypes.node,
    onSignUp: PropTypes.func,
    location: PropTypes.object,
    fromEchoShow: PropTypes.bool,
    showOpenEurope: PropTypes.bool,
    isChoseOptionPage: PropTypes.bool,
    router: PropTypes.object.isRequired,
  }

  static defaultProps = {
    displayEmailSignup: true,
    showGoogleConnect: true,
    fromEchoShow: false,
    showOpenEurope: false,
  }

  state = {
    errors: {},
    email: "",
    password: "",
    day: "",
    month: "",
    year: "",
    gender: "",
    displayEmailSignupFields: true,
  }

  static getMeta(renderProps) {
    return {
      og: {
        title: consts.i18n.pageTitle.public,
        type: "website",
        image: getAssetURL("image.pageHome.ogImage"),
        imageAlt: "Logo Molotov.tv",
        url: `${consts.externalLink.landing}${consts.routes.profile}${renderProps.location.search}`,
        imageHeight: 400,
        imageWidth: 588,
        description: "Inscrivez-vous à Molotov !",
      },
    }
  }

  emailInputRef = React.createRef()

  getContextData = () => {
    return {
      originPage: this.props.signUpOrigin,
      deviceTargetAgent: this.props.deviceTargetAgent,
    }
  }

  getPrevRouteForTracking = () => {
    // @dev tracking user registration path
    // user was redirected from 'Try now' CTA or clicked 'Create an account' at nav bar and validated registration
    if (
      (this.props.router.query?.utm_campaign && this.props.router.query.utm_campaign === "main_cta") ||
      this.props.router.query.utm_campaign === "createaccount"
    ) {
      return this.props.router.query.utm_campaign
    }

    if (this.props.router.query?.page === "offer") {
      const pathnameParts = this.props.router.pathname.split("/")

      return `offer:${pathnameParts[pathnameParts.length - 1]}`
    }

    return null
  }

  submit = () => {
    const { email, password, day, month, year, gender } = this.state

    const errors = {
      email: email.length === 0,
      password: password.length === 0,
      birthday: day.length === 0 || month.length === 0 || year.length === 0,
      day: day.length === 0,
      month: month.length === 0,
      year: year.length === 0,
      gender: gender.length === 0,
    }

    this.setState({ errors })

    if (Object.keys(errors).filter(e => errors[e]).length) {
      return
    }

    let birthday = `${year}-${month}-${day}`

    const prevRoute = this.getPrevRouteForTracking()

    this.props.dispatch(createAccount(email, password, birthday, gender, this.getContextData(), this.props.onSignUp, prevRoute))
  }

  onInputChange = (name, value) => {
    this.setState({
      errors: {
        ...this.state.errors,
        [name]: false,
        birthday: ["day", "month", "year"].indexOf(name) !== -1 ? false : this.state.errors.birthday,
      },
      [name]: value,
    })
  }

  onGenderChange = value => {
    this.setState({
      errors: {
        ...this.state.errors,
        gender: false,
      },
      gender: value,
    })
  }

  onKeyPress = event => {
    if (event.keyCode === 13) {
      this.submit()
    }
  }

  showEmailSignupField = () => {
    this.setState({
      displayEmailSignupFields: true,
    })
    this.emailInputRef.current && this.emailInputRef.current.focus()
  }

  responseFacebook = response => {
    if (response && response.accessToken) {
      this.props.dispatch(facebookLogin(response.accessToken, this.getContextData()))
    }
  }

  onGoogleResponse = data => {
    const prevRoute = this.getPrevRouteForTracking()

    this.props.dispatch(googleLogin(data.code, data.googleId, this.getContextData(), prevRoute))
  }

  onAppleLogin = data => {
    this.props.dispatch(appleLogin(data, this.getContextData()))
  }

  onClickConnect = () => {
    window.scrollTo(0, 0)
    this.props.onClickConnect()
  }

  renderCguConditions = () => {
    return (
      <div className={styles.cgu}>
        {Formatter.advanced(i18n.signupPage.cgu, {
          cguConditions: <Link to={routes.gtu}>{i18n.signupPage.cguConditions}</Link>,
          cguPolitics: <Link to={routes.privacy}>{i18n.signupPage.cguPolitics}</Link>,
          cguCookies: <Link to={routes.cookies}>{i18n.cookies}</Link>,
        })}
      </div>
    )
  }

  componentDidMount() {
    window.addEventListener("keypress", this.onKeyPress)

    if (routeHelpers.isGiftReceiverPage(this.props.location.pathname)) {
      TrackingSegment.trackPage("gift_receiver", {
        gift_receiver_step: "sign_up",
      })
    }
    updateMeta(PageSignup.getMeta(this.props))
  }

  componentWillUnmount() {
    restoreDefaultMeta()

    window.removeEventListener("keypress", this.onKeyPress)
    // Force to display LoginPage after logout
    this.props.dispatch(setSignup(""))
    this.props.dispatch(clearLoginError())
  }

  render() {
    const { account, errorMessages, showGoogleConnect, title, fromEchoShow, showOpenEurope, isChoseOptionPage } = this.props

    const { errors } = this.state

    const displayEmailSignupFields = this.state.displayEmailSignupFields || this.props.displayEmailSignup

    const finalError = account.error && account.error.message

    const googleEnabled = consts.config.googleEnabled && showGoogleConnect

    return (
      <div>
        <div className={styles.root}>
          {isChoseOptionPage && <div className={styles.step}>ÉTAPE 1 SUR 3</div>}
          {title || <h3 className={styles.title}>Inscription</h3>}
          {showOpenEurope && (
            <div className={styles.openEurope}>
              <strong>Rappel : </strong>
              {i18n.signupPage.openEurope}
            </div>
          )}
          {!fromEchoShow && (googleEnabled || consts.config.appleEnabled) ? (
            <div>
              {googleEnabled && <ButtonGoogleLogin rootStyle={styles.googleButton} onGoogleResponse={this.onGoogleResponse} />}
              {consts.config.appleEnabled && <ButtonAppleLogin rootStyle={classNames(styles.appleButton)} onAppleResponse={this.onAppleLogin} />}
              <Separator text="ou" />
            </div>
          ) : null}

          {!displayEmailSignupFields && (
            <Button
              dataTest="register-with-email"
              color={Button.colors.greyFull}
              value={i18n.signupPage.withEmail}
              onClick={this.showEmailSignupField}
              customStyle={styles.emailSignupFieldButton}
            />
          )}

          {!displayEmailSignupFields && this.renderCguConditions()}

          <div style={{ maxHeight: displayEmailSignupFields ? "3000px" : 0, displayEmailSignupFields }} className={styles.emailSignupFields}>
            <FormLabel customStyle={styles.label} title={i18n.signupPage.email} />
            <Input
              inputRef={this.emailInputRef}
              rootStyle={classNames(styles.inputRoot, { [styles.invalid]: errors.email })}
              name="email"
              type={"email"}
              customStyle={classNames(styles.input, { [styles.inputError]: errors.email })}
              placeholder={i18n.signupPage.emailPlaceholder}
              onChange={value => {
                this.onInputChange("email", value)
              }}
              autoComplete={"username"}
            />
            {errors.email && <div className={styles.errorMessage}>{errorMessages.ERR_ACCOUNT_CREATION_INVALID_EMAIL}</div>}
            <FormLabel customStyle={styles.label} title={i18n.signupPage.password} />
            <Input
              type="password"
              placeholder={i18n.signupPage.passwordPlaceholder}
              rootStyle={classNames(styles.inputRoot, { [styles.invalid]: errors.password })}
              customStyle={classNames(styles.input, { [styles.inputError]: errors.password })}
              onChange={value => {
                this.onInputChange("password", value)
              }}
              autoComplete={"new-password"}
            />
            {errors.password && <div className={styles.errorMessage}>{errorMessages.ERR_ACCOUNT_CREATION_MISSING_PASSWORD}</div>}
            <BirthdayForm errors={errors} errorMessages={errorMessages} onInputChange={this.onInputChange} />
            <GenderForm error={errors.gender} errorMessages={errorMessages} onChange={this.onGenderChange} gender={this.state.gender} />

            <div className={styles.cgu}>
              {Formatter.advanced(i18n.signupPage.cgu, {
                cguConditions: <Link to={routes.gtu}>{i18n.signupPage.cguConditions}</Link>,
                cguPolitics: <Link to={routes.gtu}>{i18n.signupPage.cguPolitics}</Link>,
                cguCookies: <Link to={routes.cookies}>{i18n.signupPage.cguCookies}</Link>,
              })}
            </div>
            {finalError && (
              <div className={styles.errorForm}>
                <span>{finalError}</span>
              </div>
            )}
            <Button
              color={Button.colors.yellowFull}
              name="signup-button"
              customStyle={classNames(styles.button, {
                [styles.disabled]: account.pending,
              })}
              onClick={this.submit}
              value={i18n.signupPage.submit}
            />
          </div>
        </div>
        <div className={styles.connectLink}>
          <div onClick={this.onClickConnect} className={styles.styleConnectLink}>
            {i18n.signupPage.connect}
          </div>
        </div>
      </div>
    )
  }
}
